// extracted by mini-css-extract-plugin
export var lbContainerOuter = "s_rJ";
export var lbContainerInner = "s_rK";
export var movingForwards = "s_rL";
export var movingForwardsOther = "s_rM";
export var movingBackwards = "s_rN";
export var movingBackwardsOther = "s_rP";
export var lbImage = "s_rQ";
export var lbOtherImage = "s_rR";
export var prevButton = "s_rS";
export var nextButton = "s_rT";
export var closing = "s_rV";
export var appear = "s_rW";